import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import {
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { API_URL } from "..";
import Center from "../components/Center";

function LoginPage() {
  let [token, setToken] = useState("");
  let [loading, setLoading] = useState(false);

  const login = async () => {
    try {
      setLoading(true);
      let response = await fetch(API_URL + "/api/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      });
      let data = await response.json();
      setLoading(false);
      if (data.success) {
        localStorage.setItem("radio_token", token);
        window.location.href = "/dashboard";
      } else {
        alert("Invalid token");
      }
    } catch (e) {
      console.error(e);
      alert("An error occurred");
      setLoading(false);
    }
  };

  return (
    <Center>
      <Card style={{ width: "100%", maxWidth: "20rem" }}>
        <CardContent>
          <Stack spacing={2.5} alignItems="center">
            <Typography variant="subtitle2">RADIO ADMIN PANEL</Typography>
            <Typography variant="h5">Login</Typography>
            <TextField
              fullWidth
              type="password"
              size="small"
              label="Token"
              variant="outlined"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
            <Button
              variant="contained"
              fullWidth
              disabled={loading}
              onClick={login}
            >
              {loading ? <HourglassTopIcon /> : "Login"}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Center>
  );
}

export default LoginPage;
