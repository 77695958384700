import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { navigateTo } from "../..";
import Center from "../../components/Center";
import DashboardLayout from "../../components/DashboardLayout";
import authFetch from "../../utils/authFetch";

function DashboardNewSchedulePage() {
  let [loading, setLoading] = useState(false);
  let [name, setName] = useState("");
  let [startAt, setStartAt] = useState(dayjs());
  let [endAt, setEndAt] = useState(dayjs().add(1, "hour"));

  const save = async () => {
    try {
      let [res] = await authFetch(
        "/api/schedules",
        setLoading,
        {
          name,
          startAt: dayjsToSecondsFromMidnight(startAt) || 0,
          endAt: dayjsToSecondsFromMidnight(endAt) || 0,
        },
        "POST"
      );
      if (res.status === 200) navigateTo("/dashboard/schedules");
      else alert("Fehler beim Speichern");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <DashboardLayout>
      {loading ? (
        <Center>
          <CircularProgress />
        </Center>
      ) : (
        <Stack spacing={1}>
          <Typography variant="h5">Neues Schedule erstellen</Typography>
          <RouterLink to="/dashboard/schedules">
            <Link>{"<-"} Zurück</Link>
          </RouterLink>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <TextField
                  label="Name"
                  size="small"
                  variant="filled"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Stack direction="row" spacing={2}>
                  <TimePicker
                    ampm={false}
                    label="Start"
                    variant="filled"
                    size="small"
                    value={startAt}
                    onChange={(date) => setStartAt(date)}
                  />
                  <TimePicker
                    ampm={false}
                    label="Ende"
                    variant="filled"
                    size="small"
                    value={endAt}
                    onChange={(date) => setEndAt(date)}
                  />
                </Stack>
              </Stack>
            </CardContent>

            <CardActions>
              <RouterLink to="/dashboard/schedules">
                <Button variant="text">Zurück</Button>
              </RouterLink>
              <Button variant="contained" onClick={() => save()}>
                Speichern
              </Button>
            </CardActions>
          </Card>
        </Stack>
      )}
    </DashboardLayout>
  );
}

function dayjsToSecondsFromMidnight(dayjs) {
  return dayjs.diff(dayjs.startOf("day"), "second");
}

export default DashboardNewSchedulePage;
