import { Add, Edit } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import Center from "../../components/Center";
import DashboardLayout from "../../components/DashboardLayout";
import { RouterLink } from "../../components/RouterLink";
import authFetch from "../../utils/authFetch";

const columns = [
  { field: "_id", headerName: "ID", width: 90 },
  { field: "name", headerName: "Name", width: 200 },
  { field: "startAt", headerName: "Startet bei", width: 130, renderCell: (params) => secondsToTime(params.value) },
  { field: "endAt", headerName: "Endet bei", width: 130, renderCell: (params) => secondsToTime(params.value) },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return (
        <Stack direction="row" spacing={0.5}>
          <RouterLink to={`/dashboard/schedules/${params.row._id}`}>
            <IconButton size="small">
              <Edit sx={{ fontSize: 20 }} />
            </IconButton>
          </RouterLink>
        </Stack>
      );
    },
  },
];

function DashboardSchedulesPage() {
  let [loading, setLoading] = useState(true);
  let [schedules, setSchedules] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let [, data] = await authFetch("/api/schedules", setLoading);
        setSchedules(data.schedules);
      } catch (e) {
        console.log("Error fetching config", e);
      }
    }
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      {loading ? (
        <Center>
          <CircularProgress />
        </Center>
      ) : (
        <Stack spacing={1}>
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Schedules</Typography>
            <RouterLink to="/dashboard/schedules/new">
              <Button variant="contained">
                <Add />
                Schedule erstellen
              </Button>
            </RouterLink>
          </Stack>

          <Paper>
            <DataGrid
              density="compact"
              rows={schedules}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              getRowId={(row) => row._id}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          </Paper>
        </Stack>
      )}
    </DashboardLayout>
  );
}

function secondsToTime(seconds) {
  let date = new Date(0);
  date.setSeconds(seconds);
  return date.toISOString().substr(11, 5);
}

export default DashboardSchedulesPage;
