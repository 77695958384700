import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { navigateTo } from "../..";
import Center from "../../components/Center";
import DashboardLayout from "../../components/DashboardLayout";
import authFetch from "../../utils/authFetch";

function DashboardEditSchedulePage() {
  const { id } = useParams();
  if (!id) navigateTo("/dashboard/schedules");

  let [loading, setLoading] = useState(true);

  let [name, setName] = useState("");
  let [startAt, setStartAt] = useState();
  let [endAt, setEndAt] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        let [, data] = await authFetch("/api/schedules/" + id, setLoading);
        setName(data.schedule.name);
        setStartAt(secondsToDayjs(data.schedule.startAt));
        setEndAt(secondsToDayjs(data.schedule.endAt));
      } catch (e) {
        console.log("Error fetching song", e);
      }
    }
    fetchData();
  }, [id]);

  const save = async () => {
    try {
      let [res] = await authFetch(
        "/api/schedules/" + id,
        setLoading,
        {
          name,
          startAt: dayjsToSecondsFromMidnight(startAt) || 0,
          endAt: dayjsToSecondsFromMidnight(endAt) || 0,
        },
        "PUT"
      );
      if (res.status !== 200) alert("Fehler beim Speichern");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <DashboardLayout>
      {loading ? (
        <Center>
          <CircularProgress />
        </Center>
      ) : (
        <Stack spacing={1}>
          <Typography variant="h5">Schedules</Typography>
          <RouterLink to="/dashboard/schedules">
            <Link>{"<-"} Zurück</Link>
          </RouterLink>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <TextField
                  label="Name"
                  size="small"
                  variant="filled"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Stack direction="row" spacing={2}>
                  <TimePicker
                    ampm={false}
                    label="Start"
                    variant="filled"
                    size="small"
                    value={startAt}
                    onChange={(date) => setStartAt(date)}
                  />
                  <TimePicker
                    ampm={false}
                    label="Ende"
                    variant="filled"
                    size="small"
                    value={endAt}
                    onChange={(date) => setEndAt(date)}
                  />
                </Stack>
              </Stack>
            </CardContent>
            <CardActions>
              <Button
                color="error"
                variant="contained"
                onClick={async () => {
                  if (
                    window.confirm(
                      "Sind Sie sicher, dass Sie dieses Schedule löschen möchten?"
                    )
                  ) {
                    await authFetch(
                      "/api/schedules/" + id,
                      setLoading,
                      undefined,
                      "DELETE"
                    );
                    navigateTo("/dashboard/schedules");
                  }
                }}
              >
                Löschen
              </Button>
              <Button variant="contained" onClick={() => save()}>
                Speichern
              </Button>
              <Button
                variant="contained"
                onClick={async () => {
                  await save();
                  navigateTo("/dashboard/schedules");
                }}
              >
                Speichern & Zurück
              </Button>
              <RouterLink to="/dashboard/schedules">
                <Button variant="text">Zurück</Button>
              </RouterLink>
            </CardActions>
          </Card>
        </Stack>
      )}
    </DashboardLayout>
  );
}

function secondsToDayjs(seconds) {
  return dayjs().startOf("day").add(seconds, "second");
}
function dayjsToSecondsFromMidnight(dayjs) {
  return dayjs.diff(dayjs.startOf("day"), "second");
}
export default DashboardEditSchedulePage;
