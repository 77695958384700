import { Stack, Typography } from "@mui/material";
import DashboardLayout from "../../../components/DashboardLayout";

function DashboardImportFilePage() {
  return (
    <DashboardLayout>
      <Stack spacing={1}>
        <Typography variant="h5">MP3-Datei importieren</Typography>
        <Typography variant="subtitle1">Noch nicht verfügbar</Typography>
      </Stack>
    </DashboardLayout>
  );
}
export default DashboardImportFilePage;
