import {
  Dashboard,
  Flag,
  LibraryMusic,
  Menu as MenuIcon,
  OpenInNew,
  PlayArrow,
  PlaylistAdd,
  PowerOff,
  Schedule,
  SmartToy,
  Stop,
  Warning,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  CircularProgress,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slider,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_URL, PlayerContext } from "..";
import authFetch from "../utils/authFetch";
import formatTime from "../utils/formatTime";
import { RouterLink } from "./RouterLink";

const drawerWidth = 240;

function DashboardLayout({ children }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <Link
          to="/dashboard"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Dashboard" color="primary" />
          </ListItem>
        </Link>

        <Link
          to="/dashboard/autodj"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <SmartToy />
            </ListItemIcon>
            <ListItemText primary="AutoDJ" color="primary" />
          </ListItem>
        </Link>
        <Link
          to="/dashboard/songs"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <LibraryMusic />
            </ListItemIcon>
            <ListItemText primary="Songs" color="primary" />
          </ListItem>
        </Link>
        <Link
          to="/dashboard/schedules"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <Schedule />
            </ListItemIcon>
            <ListItemText primary="Schedules" color="primary" />
          </ListItem>
        </Link>
        <Link
          to="/dashboard/imports"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <PlaylistAdd />
            </ListItemIcon>
            <ListItemText primary="Importieren" color="primary" />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            localStorage.removeItem("radio_token");
            window.location.href = "/login";
          }}
        >
          <ListItemIcon>
            <PowerOff />
          </ListItemIcon>
          <ListItemText primary="Abmelden" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          pr={2}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="subtitle2" noWrap>
              RADIO ADMIN PANEL
            </Typography>
          </Toolbar>
          <Player />
        </Stack>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

let audio = new Audio();
audio.volume = 0.2;

const Player = memo(_Player);

function _Player() {
  let [currentSong, setCurrentSong] = useState("Nothing - Nothing");
  let [currentTime, setCurrentTime] = useState("0:00");
  let [length, setLength] = useState("0:00");

  let [songId, setSongId] = useState(null);
  let [isJingle, setIsJingle] = useState(false);

  let [workRequired, setWorkRequired] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await fetch(API_URL + "/api/nowplaying");
        let data = await response.json();
        setCurrentSong(data.currentSong);
        setCurrentTime(formatTime(data.playbackPosition));
        setLength(formatTime(data.songLength));
        setSongId(data.currentSongId);
        setIsJingle(data.jingle);
        setWorkRequired(data.workRequired);
      } catch (e) {
        console.log("Error fetching now playing", e);
        setCurrentSong("Error fetching now playing");
        setCurrentTime("0:00");
        setLength("0:00");
        setSongId(null);
        setIsJingle(false);
        setWorkRequired(false);
      }
    }
    let interval = setInterval(fetchData, 1000);
    fetchData();
    return () => clearInterval(interval);
  }, []);

  let { loading, playing, playPause, volume, setVolume } =
    useContext(PlayerContext);

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <IconButton onClick={() => playPause()} disabled={loading}>
        {loading ? (
          <CircularProgress size={24} />
        ) : playing ? (
          <Stop />
        ) : (
          <PlayArrow />
        )}
      </IconButton>
      <Slider
        size="small"
        sx={{ width: "4.33rem" }}
        defaultValue={0.2}
        min={0}
        max={1}
        step={0.01}
        value={volume}
        onChange={(e, v) => setVolume(v)}
      />
      <Stack>
        <Typography variant="subtitle2">Spielt gerade</Typography>
        <Typography variant="body2">
          {currentSong} ({currentTime} / {length})
        </Typography>
      </Stack>
      {workRequired && (
        <Tooltip title="Muss bearbeitet werden">
          <Warning color="warning" />
        </Tooltip>
      )}
      {!!songId && (
        <RouterLink to={"/dashboard/songs/" + songId} target="_blank">
          <IconButton size="small" target="_blank">
            <OpenInNew />
          </IconButton>
        </RouterLink>
      )}
      {!isJingle && !!songId && (
        <Tooltip title="Als 'Muss bearbeitet werden' markieren">
          <IconButton
            color="warning"
            size="small"
            onClick={async (e) => {
              await authFetch(
                "/api/markWorkRequired",
                () => {},
                { id: songId },
                "POST"
              );
              setWorkRequired(!workRequired);
            }}
          >
            <Flag />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
}

export default DashboardLayout;
