import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Center from "../../../components/Center";
import DashboardLayout from "../../../components/DashboardLayout";
import authFetch from "../../../utils/authFetch";
import { navigateTo } from "../../..";

function DashboardImportPlaylistPage() {
  let [loading, setLoading] = useState(false);
  let [url, setUrl] = useState("");
  let [priority, setPriority] = useState(1);
  let [sources, setSources] = useState(["import", "ytdl"]);
  return (
    <DashboardLayout>
      {loading ? (
        <Center>
          <CircularProgress />
        </Center>
      ) : (
        <Stack spacing={1}>
          <Typography variant="h5">YouTube Playlist importieren</Typography>
          <Card>
            <CardContent>
              <Stack spacing={1}>
                <TextField
                  label="YouTube Playlist URL"
                  variant="filled"
                  fullWidth
                  size="small"
                  placeholder="https://www.youtube.com/playlist?list=PLfirGkCPxbmEgZAsRiu9WyOGCAVEWPwha"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
                <Divider>Import-Einstellungen</Divider>
                <Stack spacing={1} direction="row">
                  <TextField
                    label="Priorität"
                    variant="filled"
                    type="number"
                    fullWidth
                    size="small"
                    placeholder="1"
                    value={priority}
                    onChange={(e) =>
                      setPriority(parseInt(Math.max(1, e.target.value)))
                    }
                  />
                  <Stack style={{ width: "100%" }}>
                    <TextField
                      label="Tags (Sources)"
                      size="small"
                      variant="filled"
                      value={sources.join(", ")}
                      onChange={(e) =>
                        setSources(
                          e.target.value.split(",").map((s) => s.trim())
                        )
                      }
                    />
                    <Typography variant="caption" color="text.secondary">
                      Durch Komma getrennte Liste von Tags.
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  if (!url || !priority || !sources) {
                    alert("Bitte fülle alle Felder aus");
                    return;
                  }
                  setLoading(true);
                  let [res] = await authFetch(
                    "/api/importPlaylist",
                    setLoading,
                    {
                      url,
                      priority,
                      sources,
                    },
                    "POST"
                  );
                  setLoading(false);
                  if (res.status !== 200) {
                    alert("Fehler beim Importieren");
                    return;
                  }
                  navigateTo("/dashboard/imports");
                }}
              >
                Importieren
              </Button>
            </CardActions>
          </Card>
        </Stack>
      )}
    </DashboardLayout>
  );
}
export default DashboardImportPlaylistPage;
