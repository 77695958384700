function Secured({ children }) {
  let token = localStorage.getItem("radio_token");
  if (token === "" || token === null) {
    window.location.href = "/login";
  }

  return children;
}

export default Secured;
