import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Center from "../../components/Center";
import DashboardLayout from "../../components/DashboardLayout";
import authFetch from "../../utils/authFetch";

function DashboardAutoDJPage() {
  let [loading, setLoading] = useState(true);
  let [config, setConfig] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        let [, data] = await authFetch("/api/config", setLoading);
        setConfig(data);
      } catch (e) {
        console.log("Error fetching config", e);
      }
    }
    fetchData();
  }, []);

  const save = async (_config = undefined) => {
    try {
      await authFetch("/api/config", setLoading, _config || config, "PUT");
      let [, data] = await authFetch("/api/config", setLoading);
      setConfig(data);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <DashboardLayout>
      {loading ? (
        <Center>
          <CircularProgress />
        </Center>
      ) : (
        <Stack spacing={1}>
          <Typography variant="h5">AutoDJ</Typography>
          {config.enableAutoDj ? (
            <Alert severity="success">
              <Stack spacing={1}>
                <Typography variant="body1">
                  Der AutoDJ ist <b>aktiviert</b>.
                </Typography>
                <Typography variant="body2">
                  Wenn der AutoDJ deaktiviert ist, werden keine Songs mehr zur
                  Warteschlange hinzugefügt.
                </Typography>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  sx={{ width: "fit-content" }}
                  onClick={() => {
                    save({ enableAutoDj: false });
                  }}
                >
                  AutoDJ Deaktivieren
                </Button>
              </Stack>
            </Alert>
          ) : (
            <Alert severity="error">
              <Stack spacing={1}>
                <Typography variant="body1">
                  Der AutoDJ ist <b>deaktiviert</b>.
                </Typography>
                <Typography variant="body2">
                  Wenn der AutoDJ deaktiviert ist, werden keine Songs mehr zur
                  Warteschlange hinzugefügt.
                </Typography>
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  sx={{ width: "fit-content" }}
                  onClick={() => {
                    save({ enableAutoDj: true });
                  }}
                >
                  AutoDJ Aktivieren
                </Button>
              </Stack>
            </Alert>
          )}
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <div>
                  <TextField
                    fullWidth
                    label="Tags Blacklist"
                    size="small"
                    variant="filled"
                    value={config.ignoreSources.join(", ")}
                    onChange={(e) =>
                      setConfig({
                        ...config,
                        ignoreSources: e.target.value
                          .split(",")
                          .map((s) => s.trim()),
                      })
                    }
                  />
                  <Typography variant="caption" color="text.secondary">
                    Durch Komma getrennte Liste von Tags, die nicht für den
                    AutoDJ verwendet werden sollen.
                  </Typography>
                </div>
                <div>
                  <TextField
                    fullWidth
                    label="Tags Whitelist"
                    size="small"
                    variant="filled"
                    value={config.requiredSources.join(", ")}
                    onChange={(e) =>
                      setConfig({
                        ...config,
                        requiredSources: e.target.value
                          .split(",")
                          .map((s) => s.trim()),
                      })
                    }
                  />
                  <Typography variant="caption" color="text.secondary">
                    Durch Komma getrennte Liste von Tags, die für den AutoDJ
                    erforderlich sind.
                  </Typography>
                </div>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Switch
                    color="primary"
                    checked={config.ignoreWorkRequired}
                    onChange={(e) =>
                      setConfig({
                        ...config,
                        ignoreWorkRequired: e.target.checked,
                      })
                    }
                  />
                  <Typography variant="body2">
                    Nur Songs verwenden, die nicht als 'Muss bearbeitet werden'
                    markiert sind
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
            <CardActions>
              <Button variant="contained" onClick={() => save()}>
                Speichern
              </Button>
            </CardActions>
          </Card>
        </Stack>
      )}
    </DashboardLayout>
  );
}

export default DashboardAutoDJPage;
