import {
  ArrowDropDown,
  Delete,
  Flag,
  OpenInNew,
  People,
  SkipNext,
  Warning,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { API_URL } from "../..";
import DashboardLayout from "../../components/DashboardLayout";
import { RouterLink } from "../../components/RouterLink";
import authFetch from "../../utils/authFetch";
import formatTime from "../../utils/formatTime";

function DashboardPage() {
  let [currentSong, setCurrentSong] = useState("Nothing - Nothing");
  let [currentTime, setCurrentTime] = useState("0:00");
  let [listenerCount, setListenerCount] = useState(0);
  let [length, setLength] = useState("0:00");
  let [progress, setProgress] = useState(-1);

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await fetch(API_URL + "/api/nowplaying");
        let data = await response.json();
        setCurrentSong(data.currentSong);
        if (data.songLength > 0)
          setProgress((data.playbackPosition / data.songLength) * 100);
        else setProgress(-1);
        setCurrentTime(formatTime(data.playbackPosition));
        setLength(formatTime(data.songLength));
        setListenerCount(data.listeners);
      } catch (e) {
        console.log("Error fetching now playing", e);
      }
    }
    let interval = setInterval(fetchData, 1000);
    fetchData();
    return () => clearInterval(interval);
  }, []);

  let [queue, setQueue] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let [, data] = await authFetch("/api/queue");
        setQueue(data.queue);
      } catch (e) {
        console.log("Error fetching queue", e);
      }
    }
    let interval = setInterval(fetchData, 1000);
    fetchData();
    return () => clearInterval(interval);
  }, []);

  const markAsWorkRequired = async (id) => {
    await authFetch("/api/markWorkRequired", () => {}, { id }, "POST");
    setQueue((prev) =>
      prev.map((song) => {
        if (song.id === id) song.workRequired = !song.workRequired;
        return song;
      })
    );
  };

  const skipToQueue = async (index) => {
    await authFetch("/api/skipToQueue", () => {}, { index }, "POST");
  };

  const removeFromQueue = async (index) => {
    await authFetch("/api/removeQueue", () => {}, { index }, "POST");
    setQueue((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <DashboardLayout>
      <Stack spacing={1}>
        <Typography variant="h5">Dashboard</Typography>
        <Card>
          <CardContent>
            <Typography variant="subtitle2">Spielt gerade</Typography>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography variant="body1">{currentSong}</Typography>
              <Typography variant="body1">
                {currentTime} / {length}
              </Typography>
            </Stack>
          </CardContent>
          <LinearProgress
            variant={progress === -1 ? "indeterminate" : "determinate"}
            value={progress}
          />
        </Card>
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{ opacity: 0.6 }}
        >
          <People />
          <Typography variant="subtitle1">{listenerCount} Zuhörer</Typography>
        </Stack>
        <Typography variant="h6">Warteschlange</Typography>
        <Paper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Estimated start</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {queue.map((song, i) => (
                <TableRow key={i}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center">
                      {song.name}
                      {song.workRequired && (
                        <Tooltip sx={{ ml: 2 }} title="Muss bearbeitet werden">
                          <Warning color="warning" />
                        </Tooltip>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>{formatTime(song.length)}</TableCell>
                  <TableCell>{formatTime(song.estimatedStart)}</TableCell>
                  <TableCell>
                    <Stack direction="row">
                      {song.id && (
                        <RouterLink to={"/dashboard/songs/" + song.id}>
                          <IconButton size="small">
                            <OpenInNew />
                          </IconButton>
                        </RouterLink>
                      )}
                      <Tooltip title="Remove from queue">
                        <IconButton
                          onClick={() => removeFromQueue(i)}
                          size="small"
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                      {!song.jingle && (
                        <Tooltip title="Als 'Muss bearbeitet werden' markieren">
                          <IconButton
                            color="warning"
                            onClick={() => markAsWorkRequired(song.id)}
                            size="small"
                          >
                            <Flag />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Skip to this song">
                        <IconButton onClick={() => skipToQueue(i)} size="small">
                          <SkipNext />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <AddButton />
      </Stack>
    </DashboardLayout>
  );
}

function AddButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleItem = async (type) => {
    setAnchorEl(null);
    await authFetch("/api/autoAddSong", () => {}, { type }, "POST");
  };
  return (
    <div>
      <Button onClick={handleClick} variant="outlined">
        Hinzufügen
        <ArrowDropDown />
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleItem("auto")}>
          Automatisch wählen
        </MenuItem>
        <MenuItem onClick={() => handleItem("song")}>Song</MenuItem>
        <MenuItem onClick={() => handleItem("mashup")}>Mashup</MenuItem>
        <MenuItem onClick={() => handleItem("jingle")}>Jingle</MenuItem>
      </Menu>
    </div>
  );
}

export default DashboardPage;
