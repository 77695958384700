import fetchAlbumArt from "album-art";

export default function getCoverArt(song, artist, cb) {
  let cache = localStorage.getItem("coverArtCache");
  if (cache) {
    cache = JSON.parse(cache);
  } else {
    cache = {};
  }
  if (cache[song + artist]) {
    console.log("Cache hit for", song, artist);
    cb(cache[song + artist]);
  } else {
    console.log("Cache miss for", song, artist);
    fetchAlbumArt(
      artist,
      {
        album: song,
      },
      (err, url) => {
        if (err) {
          console.error(err);
          return;
        }
        cache[song + artist] = url;
        localStorage.setItem("coverArtCache", JSON.stringify(cache));
        cb(url);
      }
    );
  }
}
