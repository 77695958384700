import {
  Delete,
  Edit,
  Flag,
  PlayArrow,
  PlaylistAdd,
} from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Center from "../../components/Center";
import DashboardLayout from "../../components/DashboardLayout";
import { RouterLink } from "../../components/RouterLink";
import authFetch from "../../utils/authFetch";

const columns = [
  { field: "_id", headerName: "ID", width: 90 },
  { field: "artist", headerName: "Artist", width: 130 },
  { field: "title", headerName: "Title", width: 200 },
  { field: "length", headerName: "Length", width: 100 },
  {
    field: "schedule",
    headerName: "Schedule",
    width: 230,
    renderCell: (params) =>
      params.value ? (
        <Tooltip title={formatScheduleTime(params.row.schedule)}>
          {params.row.schedule?.name}
          {params.row.scheduleType === "require"
            ? " (erforderlich)"
            : " (bevorzugt)"}
        </Tooltip>
      ) : (
        <span style={{ opacity: 0.33 }}>-</span>
      ),
    valueGetter: (value) => value?.name,
  },
  { field: "workRequired", headerName: "Work Required", width: 130 },
  { field: "priority", headerName: "Priority", width: 80 },
  {
    field: "sources",
    headerName: "Sources",
    width: 130,
    valueGetter: (value) => value?.join?.(", "),
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return (
        <Stack direction="row" spacing={0.5}>
          <RouterLink to={`/dashboard/songs/${params.row._id}`}>
            <IconButton size="small">
              <Edit sx={{ fontSize: 20 }} />
            </IconButton>
          </RouterLink>
          <Tooltip title="In die Warteschlange">
            <IconButton
              size="small"
              onClick={async () => {
                await authFetch(
                  "/api/queueSong/",
                  () => {},
                  { id: params.row._id },
                  "POST"
                );
              }}
            >
              <PlaylistAdd sx={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Jetzt spielen">
            <IconButton
              size="small"
              onClick={async () => {
                if (
                  window.confirm(
                    "Bist du sicher, dass du diesen Song spielen möchtest?"
                  )
                ) {
                  await authFetch(
                    "/api/playSong/",
                    () => {},
                    { id: params.row._id },
                    "POST"
                  );
                }
              }}
            >
              <PlayArrow sx={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        </Stack>
      );
    },
  },
];

function DashboardSongsPage() {
  let [loading, setLoading] = useState(true);
  let [songs, setSongs] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let [, data] = await authFetch("/api/songs", setLoading);
        setSongs(data.songs);
      } catch (e) {
        console.log("Error fetching config", e);
      }
    }
    fetchData();
  }, []);

  let [selectionModel, setSelectionModel] = useState();
  const deleteSongs = async (ids) => {
    try {
      await authFetch("/api/deleteSongs", setLoading, { songs: ids }, "DELETE");
      let [, data] = await authFetch("/api/songs", setLoading);
      setSongs(data.songs);
    } catch (e) {
      console.error(e);
    }
  };

  const markWorkRequired = async (ids) => {
    try {
      await authFetch(
        "/api/toggleWorkRequiredSongs",
        setLoading,
        { songs: ids },
        "PUT"
      );
      let [, data] = await authFetch("/api/songs", setLoading);
      setSongs(data.songs);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <DashboardLayout>
      {loading ? (
        <Center>
          <CircularProgress />
        </Center>
      ) : (
        <Stack spacing={1}>
          <Typography variant="h5">Songs</Typography>
          <Paper>
            {selectionModel?.length > 0 && (
              <Stack
                my={1}
                mx={2}
                alignItems="center"
                direction="row"
                spacing={2}
              >
                <Typography variant="body2">
                  Selected: {selectionModel.length}
                </Typography>
                <Stack alignItems="center" direction="row">
                  <Tooltip title="Alle 'Muss bearbeitet werden' umschalten">
                    <IconButton
                      size="small"
                      color="warning"
                      onClick={() => {
                        markWorkRequired(selectionModel);
                      }}
                    >
                      <Flag sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Alle löschen">
                    <IconButton
                      onClick={() => {
                        if (
                          !window.confirm(
                            "Bist du sicher, dass du die ausgewählten Songs löschen möchtest?"
                          )
                        )
                          return;
                        deleteSongs(selectionModel);
                      }}
                      size="small"
                      color="error"
                    >
                      <Delete sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            )}
            <DataGrid
              density="compact"
              rows={songs}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              getRowId={(row) => row._id}
              initialState={{
                filter: {
                  filterModel: {
                    items: [
                      { field: "title", operator: "contains", value: "" },
                    ],
                  },
                },
              }}
              slots={{
                toolbar: GridToolbar,
              }}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={selectionModel}
            />
          </Paper>
        </Stack>
      )}
    </DashboardLayout>
  );
}

function formatScheduleTime(schedule) {
  if (!schedule) return "";
  return `${secondsToDayjs(schedule.startAt).format(
    "HH:mm"
  )} - ${secondsToDayjs(schedule.endAt).format("HH:mm")}`;
}

function secondsToDayjs(seconds) {
  return dayjs().startOf("day").add(seconds, "second");
}

export default DashboardSongsPage;
