import { API_URL } from "..";

export default async function authFetch(
  endpoint,
  setLoading = () => {},
  body = null,
  method = "GET"
) {
  return new Promise(async (resolve, reject) => {
    try {
      if (
        localStorage.getItem("radio_token") === "" ||
        localStorage.getItem("radio_token") === null
      ) {
        window.location.href = "/login";
        return reject("Invalid token");
      }
      setLoading(true);
      let response = await fetch(API_URL + endpoint, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("radio_token"),
        },
        [body ? "body" : null]: JSON.stringify(body || {}),
      });
      if (response.status === 401) {
        alert("Invalid token");
        setLoading(false);
        localStorage.setItem("radio_token", "");
        window.location.href = "/login";
        return reject("Invalid token");
      }
      resolve([response, await response.json()]);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      reject(e);
    }
  });
}
