import { Pause, PlayArrow, ThumbDown, ThumbUp } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { AudioVisualizer } from "react-audio-visualize";
import { Link as RouterLink, useParams } from "react-router-dom";
import { API_URL, navigateTo } from "../..";
import Center from "../../components/Center";
import DashboardLayout from "../../components/DashboardLayout";
import authFetch from "../../utils/authFetch";
import formatTime from "../../utils/formatTime";

function DashboardEditSongPage() {
  const { id } = useParams();
  if (!id) navigateTo("/dashboard/songs");

  let [loading, setLoading] = useState(true);
  let [song, setSong] = useState(null);
  let [nextId, setNextId] = useState(null);
  let [schedules, setSchedules] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let [, data] = await authFetch("/api/songs/" + id, setLoading);
        setSong(data.song);
        setNextId(data.nextId);
        setSchedules(data.schedules);
      } catch (e) {
        console.log("Error fetching song", e);
      }
    }
    fetchData();
  }, [id]);

  const save = async (_song = undefined) => {
    try {
      await authFetch("/api/songs/" + id, setLoading, _song || song, "PUT");
      let [, data] = await authFetch("/api/songs/" + id, setLoading);
      setSong(data.song);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <DashboardLayout>
      {loading ? (
        <Center>
          <CircularProgress />
        </Center>
      ) : (
        <Stack spacing={1}>
          <Typography variant="h5">Songs</Typography>
          <RouterLink to="/dashboard/songs">
            <Link>{"<-"} Zurück</Link>
          </RouterLink>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Stack direction="row" alignItems="center" spacing={3}>
                  <div>
                    <Typography variant="h6">{song.title}</Typography>
                    <Typography variant="body1">{song.artist}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {song._id}
                    </Typography>
                  </div>
                  <div style={{ flexGrow: 1 }} />
                  <Stack direction="column" spacing={1}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Bewertung
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <ThumbUp />
                      <Typography variant="body1">{song.likes}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <ThumbDown />
                      <Typography variant="body1">{song.dislikes}</Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="column" spacing={0}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Zuletzt gespielt
                    </Typography>
                    <Typography variant="body2">
                      {song.lastPlayed
                        ? new Date(song.lastPlayed).toLocaleString()
                        : "Noch nie"}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      Mal gespielt
                    </Typography>
                    <Typography variant="body2">{song.timesPlayed}</Typography>
                  </Stack>
                  <Stack spacing={1}>
                    <Button
                      onClick={async () => {
                        await authFetch(
                          "/api/playSong/",
                          setLoading,
                          { id: song._id },
                          "POST"
                        );
                      }}
                      variant="outlined"
                    >
                      Jetzt spielen
                    </Button>
                    <Button
                      onClick={async () => {
                        await authFetch(
                          "/api/queueSong/",
                          setLoading,
                          { id: song._id },
                          "POST"
                        );
                      }}
                      variant="outlined"
                    >
                      In die Warteschlange
                    </Button>
                  </Stack>
                </Stack>
                <Divider />
                <SongPreview id={song._id} startAt={song.startAt} />
                <Divider />
                <Stack spacing={1}>
                  <TextField
                    label="Title"
                    size="small"
                    variant="filled"
                    value={song.title}
                    onChange={(e) =>
                      setSong({ ...song, title: e.target.value })
                    }
                  />
                  <TextField
                    label="Artist"
                    size="small"
                    variant="filled"
                    value={song.artist}
                    onChange={(e) =>
                      setSong({ ...song, artist: e.target.value })
                    }
                  />
                  <Stack>
                    <TextField
                      label="Tags (Sources)"
                      size="small"
                      variant="filled"
                      value={song.sources.join(", ")}
                      onChange={(e) =>
                        setSong({
                          ...song,
                          sources: e.target.value
                            .split(",")
                            .map((s) => s.trim()),
                        })
                      }
                    />
                    <Typography variant="caption" color="text.secondary">
                      Durch Komma getrennte Liste von Tags.
                    </Typography>
                  </Stack>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Switch
                      color="primary"
                      checked={song.workRequired}
                      onChange={(e) =>
                        setSong({ ...song, workRequired: e.target.checked })
                      }
                    />
                    <Typography variant="body2">
                      Muss bearbeitet werden
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <FormControl variant="filled" size="small" fullWidth>
                      <InputLabel id="schedule-select-label">
                        Schedule
                      </InputLabel>
                      <Select
                        labelId="schedule-select-label"
                        id="schedule-select"
                        value={song.schedule || null}
                        label="Schedule"
                        onChange={(e) =>
                          setSong({ ...song, schedule: e.target.value })
                        }
                      >
                        <MenuItem value={null}>
                          <em>Kein Schedule</em>
                        </MenuItem>
                        {schedules.map((s) => (
                          <MenuItem key={s._id} value={s._id}>
                            {s.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="filled"
                      size="small"
                      fullWidth
                      disabled={!song.schedule}
                    >
                      <InputLabel id="schedule-select-label">
                        Schedule
                      </InputLabel>
                      <Select
                        disabled={!song.schedule}
                        labelId="schedule-select-label"
                        id="schedule-select"
                        value={song.scheduleType || null}
                        label="Schedule Typ"
                        onChange={(e) =>
                          setSong({ ...song, scheduleType: e.target.value })
                        }
                      >
                        <MenuItem value={"require"}>
                          Erforderlich (der Song wird nur gespielt, wenn er im
                          Schedule ist)
                        </MenuItem>
                        <MenuItem value={"prefer"}>
                          Bevorzugt (der Song wird weniger oft gespielt, wenn er
                          nicht im Schedule ist)
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <TextField
                    type="number"
                    label="Priorität"
                    size="small"
                    variant="filled"
                    value={song.priority}
                    onChange={(e) =>
                      setSong({
                        ...song,
                        priority: parseInt(Math.max(1, e.target.value)) || 1,
                      })
                    }
                  />
                  <TextField
                    type="number"
                    label="Start vom Song (wenn am Start noch keine Musik ist)"
                    size="small"
                    variant="filled"
                    value={song.startAt}
                    onChange={(e) =>
                      setSong({
                        ...song,
                        startAt: parseFloat(Math.max(0, e.target.value)) || 0,
                      })
                    }
                  />
                  <TextField
                    type="number"
                    label="Länge der ganzen Audiodatei (nicht verändern)"
                    size="small"
                    variant="filled"
                    value={song.length}
                    onChange={(e) =>
                      setSong({
                        ...song,
                        length: parseFloat(Math.max(0, e.target.value)) || 0,
                      })
                    }
                  />
                </Stack>
              </Stack>
            </CardContent>
            <CardActions>
              <Button
                color="error"
                variant="contained"
                onClick={async () => {
                  if (
                    window.confirm(
                      "Sind Sie sicher, dass Sie diesen Song löschen möchten?"
                    )
                  ) {
                    await authFetch(
                      "/api/songs/" + id,
                      setLoading,
                      undefined,
                      "DELETE"
                    );
                    navigateTo("/dashboard/songs");
                  }
                }}
              >
                Löschen
              </Button>
              <Button variant="contained" onClick={() => save()}>
                Speichern
              </Button>
              <Button
                variant="contained"
                onClick={async () => {
                  await save();
                  navigateTo("/dashboard/songs");
                }}
              >
                Speichern & Zurück
              </Button>
              <Button
                variant="contained"
                disabled={!nextId}
                onClick={async () => {
                  await save();
                  navigateTo("/dashboard/songs/" + nextId);
                }}
              >
                Speichern & Nächster
              </Button>
              <RouterLink to="/dashboard/songs">
                <Button variant="text">Zurück</Button>
              </RouterLink>
            </CardActions>
          </Card>
        </Stack>
      )}
    </DashboardLayout>
  );
}

function SongPreview({ id, startAt }) {
  let [error, setError] = useState(false);

  const [blob, setBlob] = useState(null);
  const visualizer = useRef(null);

  useEffect(() => {
    // get mp3
    async function fetchData() {
      try {
        let res = await fetch(API_URL + "/processed_songs/" + id + ".mp3");
        if (res.status === 404) {
          setError(true);
          return;
        }
        let blob = await res.blob();
        setBlob(blob);
      } catch (e) {
        console.log("Error fetching config", e);
      }
    }
    fetchData();
  }, [id]);

  let [left, setLeft] = useState(0);
  let [time, setTime] = useState("00:00");
  let [duration, setDuration] = useState(0);

  let togglePlay = useRef(() => {});
  let setVolume = useRef(() => {});
  let seek = useRef(() => {});
  let [playing, setPlaying] = useState(false);

  useEffect(() => {
    let audio = new Audio();
    audio.volume = 0.2;
    setVolume.current = (volume) => (audio.volume = volume);
    seek.current = (time) => (audio.currentTime = time * audio.duration);
    audio.src = API_URL + "/processed_songs/" + id + ".mp3";
    audio.onplaying = () => {
      setPlaying(true);
      setDuration(audio.duration);
      let interval = setInterval(() => {
        setLeft((audio.currentTime / audio.duration) * 100);
        setTime(formatTime(Math.floor(audio.currentTime)));
      }, 100);
      audio.onended = () => clearInterval(interval);
    };
    togglePlay.current = () => {
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }
    };
    audio.onpause = () => setPlaying(false);
    return () => {
      audio.pause();
    };
  }, [id]);
  if (error)
    return (
      <Typography variant="body1">
        Vorschau konnte nicht geladen werden (Datei fehlt?)
      </Typography>
    );
  return (
    <Stack spacing={1}>
      <div
        style={{ width: "100%", position: "relative" }}
        onClick={(e) => {
          let rect = e.target.getBoundingClientRect();
          let x = e.clientX - rect.left;
          let width = rect.width;
          let time = x / width;
          seek.current(time);
        }}
      >
        {blob && (
          <AudioVisualizer
            ref={visualizer}
            style={{
              backgroundColor: "#111",
              width: "100%",
              height: "50px",
              pointerEvents: "none",
            }}
            blob={blob}
            width={1024}
            height={50}
            barWidth={1}
            gap={0}
            barColor="#fff"
          />
        )}
        <div
          style={{ left: left + "%", pointerEvents: "none" }}
          className="viz-pos"
        >
          <Typography
            className="viz-text"
            variant="caption"
            color="text.secondary"
          >
            {time}
          </Typography>
        </div>
        <div
          style={{
            left: (startAt / duration) * 100 + "%",
            pointerEvents: "none",
          }}
          className="viz-start"
        />
      </div>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction="row"
      >
        <IconButton size="small">
          {playing ? (
            <Pause onClick={togglePlay.current} />
          ) : (
            <PlayArrow onClick={togglePlay.current} />
          )}
        </IconButton>
      </Stack>
    </Stack>
  );
}

export default DashboardEditSongPage;
