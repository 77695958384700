import { styled } from "@mui/material";
import React, { useEffect, useRef } from "react";

const ScrollWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
}));

const ScrollChild = styled("div")(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "clip",
  textOverflow: "clip",
  display: "inline-block",
  // remove extra space on bottom caused by inline-block
  verticalAlign: "top",
  transition: "transform 0.5s linear",
  transform: "translateX(0)",
  // fade out the text on the right
}));

function OverflowScroll({ children }) {
  const wrapper = useRef(null);
  const child = useRef(null);

  let [fadeOutLeft, setFadeOutLeft] = React.useState(false);
  let [fadeOutRight, setFadeOutRight] = React.useState(false);

  useEffect(() => {
    if (!wrapper.current || !child.current) {
      return;
    }

    let interval, timeout, timeout2;

    function onResize() {
      clearInterval(interval);
      // check if the wrapper is smaller than the child
      if (wrapper.current.clientWidth < child.current.clientWidth) {
        let pxToMove = child.current.clientWidth - wrapper.current.clientWidth;
        child.current.style.transitionDuration = "0s";
        child.current.style.transform = `translateX(0px)`;
        requestAnimationFrame(() => {
          child.current.style.transitionDuration = pxToMove * 0.05 + "s";
          child.current.style.transform = `translateX(-${pxToMove}px)`;
          setFadeOutLeft(true);
          setFadeOutRight(true);
          let isLeft = true;
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            setFadeOutLeft(isLeft);
            setFadeOutRight(!isLeft);
          }, pxToMove * 50);
          interval = setInterval(() => {
            clearTimeout(timeout2);
            setFadeOutLeft(true);
            setFadeOutRight(true);
            if (isLeft) {
              child.current.style.transform = "translateX(0)";
            } else {
              child.current.style.transform = `translateX(-${pxToMove}px)`;
            }
            isLeft = !isLeft;
            timeout2 = setTimeout(() => {
              setFadeOutLeft(isLeft);
              setFadeOutRight(!isLeft);
            }, pxToMove * 50);
          }, Math.max(pxToMove * 50 + 1800, 3500));
        });
      } else {
        child.current.style.transitionDuration = "0s";
        child.current.style.transform = "translateX(0)";
        setFadeOutLeft(false);
        setFadeOutRight(false);
        clearTimeout(timeout);
        clearTimeout(timeout2);
      }
    }

    let resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(child.current);
    let resizeObserver2 = new ResizeObserver(onResize);
    resizeObserver2.observe(wrapper.current);
    return () => {
      resizeObserver.disconnect();
      resizeObserver2.disconnect();
      clearInterval(interval);
    };
  }, []);
  
  return (
    <ScrollWrapper
      style={{
        maskImage:
          fadeOutLeft && fadeOutRight
            ? "linear-gradient(to right, transparent, black 8%, black 92%, transparent)"
            : fadeOutLeft
            ? "linear-gradient(to right, transparent, black 8%)"
            : fadeOutRight
            ? "linear-gradient(to right, black 92%, transparent)"
            : "none",

        WebkitMaskImage:
          fadeOutLeft && fadeOutRight
            ? "linear-gradient(to right, transparent, black 8%, black 92%, transparent)"
            : fadeOutLeft
            ? "linear-gradient(to right, transparent, black 8%)"
            : fadeOutRight
            ? "linear-gradient(to right, black 92%, transparent)"
            : "none",
      }}
      ref={wrapper}
    >
      <ScrollChild ref={child}>{children}</ScrollChild>
    </ScrollWrapper>
  );
}

export default OverflowScroll;
