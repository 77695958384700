import {
  Add,
  CheckCircle,
  CleaningServices,
  Delete,
  Edit,
  Refresh,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { navigateTo } from "../..";
import Center from "../../components/Center";
import DashboardLayout from "../../components/DashboardLayout";
import { RouterLink } from "../../components/RouterLink";
import authFetch from "../../utils/authFetch";

function getStatusColor(status) {
  switch (status) {
    case "pending":
      return "lightgray";
    case "downloading":
      return "lightblue";
    case "downloaded":
      return "lightgreen";
    case "error":
      return "#ff8888";
    default:
      return "#fff";
  }
}

const columns = [
  { field: "_id", headerName: "ID", width: 90 },
  { field: "artist", headerName: "Artist", width: 130 },
  { field: "title", headerName: "Title", width: 200 },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    renderCell: (params) => {
      return params.row.status === "error" ? (
        <Tooltip title={params.row.errorMessage}>
          <span style={{ color: "#ff8888" }}>{params.value}</span>
        </Tooltip>
      ) : (
        <span style={{ color: getStatusColor(params.value) }}>
          {params.value}
        </span>
      );
    },
  },
  { field: "downloadId", headerName: "Download", width: 130 },
  { field: "priority", headerName: "Priority", width: 130 },
  {
    field: "sources",
    headerName: "Sources",
    width: 130,
    renderCell: (params) => params.value?.join?.(", "),
    valueGetter: (value) => value?.join?.(", "),
  },
  { field: "length", headerName: "Length", width: 130 },
  { field: "startAt", headerName: "Start bei", width: 130 },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return (
        <Stack direction="row" spacing={0.5}>
          <RouterLink to={`/dashboard/imports/${params.row._id}`}>
            <IconButton size="small">
              <Edit sx={{ fontSize: 20 }} />
            </IconButton>
          </RouterLink>
          {params.row.status === "downloaded" && (
            <Tooltip title="Fertigstellen">
              <IconButton
                color="success"
                size="small"
                onClick={async () => {
                  await authFetch(
                    "/api/finishImports",
                    () => {},
                    { songs: [params.row._id] },
                    "POST"
                  );
                  navigateTo("/dashboard/imports");
                }}
              >
                <CheckCircle sx={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          )}
          {params.row.status === "error" && (
            <Tooltip title="Erneut versuchen">
              <IconButton
                color="error"
                size="small"
                onClick={async () => {
                  await authFetch(
                    "/api/restartImports",
                    () => {},
                    { songs: [params.row._id] },
                    "POST"
                  );
                  navigateTo("/dashboard/imports");
                }}
              >
                <Refresh sx={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      );
    },
  },
];

function DashboardImportsPage() {
  let [loading, setLoading] = useState(true);
  let [songs, setSongs] = useState([]);

  let [refreshIn, setRefreshIn] = useState(10);
  const refresh = useRef();

  useEffect(() => {
    async function fetchData(silent = false) {
      try {
        let [, data] = await authFetch(
          "/api/imports",
          silent ? () => {} : setLoading
        );
        setSongs(data.songs);
      } catch (e) {
        console.log("Error fetching config", e);
      }
    }
    refresh.current = fetchData;
    fetchData();
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      setRefreshIn((prev) => {
        if (prev === 0) {
          refresh.current(true);
          return 10;
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  let [selectionModel, setSelectionModel] = useState();

  const deleteSongs = async (ids) => {
    try {
      await authFetch(
        "/api/deleteImports",
        setLoading,
        { songs: ids },
        "DELETE"
      );
      let [, data] = await authFetch("/api/imports", setLoading);
      setSongs(data.songs);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <DashboardLayout>
      {loading ? (
        <Center>
          <CircularProgress />
        </Center>
      ) : (
        <Stack spacing={1}>
          <Typography variant="h5">Songs Importieren</Typography>
          <Stack direction="row" spacing={0.5}>
            <RouterLink to="/dashboard/import/video">
              <Button variant="contained" size="small">
                <Add />
                YouTube Video importieren
              </Button>
            </RouterLink>
            <RouterLink to="/dashboard/import/playlist">
              <Button variant="contained" size="small">
                <Add />
                YouTube Playlist importieren
              </Button>
            </RouterLink>
            <RouterLink to="/dashboard/import/file">
              <Button variant="outlined" size="small">
                <Add />
                MP3 importieren
              </Button>
            </RouterLink>
            <Button
              variant="contained"
              size="small"
              style={{ marginLeft: "auto" }}
              onClick={() => {
                refresh.current();
                setRefreshIn(10);
              }}
            >
              <Refresh />
              Aktualisieren ({refreshIn})
            </Button>
          </Stack>
          <Paper>
            {selectionModel?.length > 0 && (
              <Stack
                my={1}
                mx={2}
                alignItems="center"
                direction="row"
                spacing={2}
              >
                <Typography variant="body2">
                  Selected: {selectionModel.length}
                </Typography>
                <Stack alignItems="center" direction="row">
                  <Tooltip title="Alle löschen">
                    <IconButton
                      onClick={() => {
                        if (
                          window.confirm(
                            "Bist du sicher, dass du die ausgewählten Importe löschen möchtest?"
                          )
                        )
                          deleteSongs(selectionModel);
                      }}
                      size="small"
                      color="error"
                    >
                      <Delete sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Ausgewählte fehlgeschlagene löschen">
                    <IconButton
                      size="small"
                      color="warning"
                      onClick={async () => {
                        await authFetch(
                          "/api/deleteErroredImports",
                          setLoading,
                          { songs: selectionModel },
                          "POST"
                        );
                        refresh.current();
                      }}
                    >
                      <CleaningServices sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Ausgewählte fertige / fehlgeschlagene neu starten">
                    <IconButton
                      size="small"
                      color="warning"
                      onClick={async () => {
                        await authFetch(
                          "/api/restartImports",
                          setLoading,
                          { songs: selectionModel },
                          "POST"
                        );
                        refresh.current();
                      }}
                    >
                      <Refresh sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Alle fertigstellen">
                    <IconButton
                      size="small"
                      color="success"
                      onClick={async () => {
                        await authFetch(
                          "/api/finishImports",
                          setLoading,
                          { songs: selectionModel },
                          "POST"
                        );
                        refresh.current();
                      }}
                    >
                      <CheckCircle sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            )}
            <DataGrid
              density="compact"
              rows={songs}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              getRowId={(row) => row._id}
              slots={{
                toolbar: GridToolbar,
              }}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={selectionModel}
            />
          </Paper>
        </Stack>
      )}
    </DashboardLayout>
  );
}

export default DashboardImportsPage;
