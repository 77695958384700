import {
  ArrowRightAlt,
  CheckCircleRounded,
  Close,
  StarOutline,
  ThumbDown,
  ThumbsUpDownOutlined,
  ThumbsUpDownRounded,
  ThumbUp,
  VolumeDownRounded,
  VolumeMuteRounded,
} from "@mui/icons-material";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import FastRewindRounded from "@mui/icons-material/FastRewindRounded";
import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import VolumeUpRounded from "@mui/icons-material/VolumeUpRounded";
import { CircularProgress, Popover, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import AudioMotionAnalyzer from "audiomotion-analyzer";
import * as React from "react";
import { toast, Toaster } from "react-hot-toast";
import { API_URL, PlayerContext } from "..";
import Center from "../components/Center";
import OverflowScroll from "../components/OverflowScroll";
import getCoverArt from "../utils/coverArtCache";

const WallPaper = styled("div")({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  overflow: "hidden",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  filter: "blur(20px) brightness(0.52)",
  transform: "scale(1.045)",
  //background: "linear-gradient(rgb(150, 38, 142) 0%, rgb(200, 105, 79) 100%)",
  opacity: 1,
  transition: "all 0ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
  /*
  "&::before": {
    content: '""',
    opacity: 0.4,
    width: "140%",
    height: "140%",
    position: "absolute",
    top: "-40%",
    right: "-50%",
    background:
      "radial-gradient(at center center, rgb(69, 79, 249) 0%, rgba(62, 79, 249, 0) 64%)",
  },
  "&::after": {
    content: '""',
    opacity: 0.4,
    width: "140%",
    height: "140%",
    position: "absolute",
    bottom: "-50%",
    left: "-30%",
    background:
      "radial-gradient(at center center, rgb(247, 237, 225) 0%, rgba(247, 237, 225, 0) 70%)",
    transform: "rotate(30deg)",
  },
  */
});

const Widget = styled("div")(({ theme }) => ({
  padding: 16,
  borderRadius: 16,
  width: 343,
  maxWidth: "100%",
  margin: "auto",
  position: "relative",
  zIndex: 1,
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.4)",
  backdropFilter: "blur(40px)",
}));

const BottomButtonContainer = styled("div")({
  zIndex: 2,
  position: "absolute",
  bottom: 16,
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  gap: 8,
});

const BottomLink = styled("a")(({ theme }) => ({
  fontSize: "0.75rem",
  color: theme.palette.mode === "dark" ? "#fff" : "#000",
  opacity: 0.5,
  fontWeight: 500,
  letterSpacing: 0.2,
  textDecoration: "none",
  "&:hover": {
    opacity: 1,
  },
  zIndex: 1,
  padding: "4px 8px",
  borderRadius: 8,
  backgroundColor: "rgba(0,0,0,0.3)",
  textAlign: "center",
}));

const BottomButton = styled("button")(({ theme }) => ({
  fontSize: "0.75rem",
  color: theme.palette.mode === "dark" ? "#fff" : "#000",
  opacity: 0.5,
  fontWeight: 500,
  letterSpacing: 0.2,
  textDecoration: "none",
  "&:hover": {
    opacity: 1,
  },
  zIndex: 1,
  padding: "4px 8px",
  borderRadius: 8,
  backgroundColor: "rgba(0,0,0,0.3)",
  border: "none",
  cursor: "pointer",
}));

const TinyText = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

const Cover = styled("div")(({ theme }) => ({
  width: 66,
  height: 66,
  borderRadius: 8,
  backgroundColor: theme.palette.mode === "dark" ? "#333" : "#f4f4f4",
  backgroundSize: "cover",
  backgroundPosition: "center",
  boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
}));

const VISUALIZER_ENABLED = true;

export default function PlayerPage() {
  const theme = useTheme();
  function formatDuration(value) {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  let [visualizerEnabled, setVisualizerEnabled] = React.useState(
    localStorage.getItem("visualizerEnabled") === "false" ? false : true
  );

  let { loading, playing, playPause, volume, setVolume, audioRef } =
    React.useContext(PlayerContext);

  let visualizerRef = React.useRef(null);
  let analyser = React.useRef(null);

  React.useEffect(() => {
    if (!VISUALIZER_ENABLED) return;
    if (!audioRef || !visualizerRef.current) return;
    //if (audioRef.paused) return;
    console.log("Initializing visualizer");
    if (analyser.current) {
      analyser.current.destroy();
    }
    let newAnalyser = new AudioMotionAnalyzer(visualizerRef.current, {
      source: audioRef,
      alphaBars: true,
      mode: 10,
      showBgColor: false,
      overlay: true,
      showScaleX: false,
      ledBars: true,
      gradient: "steelblue",
    });
    analyser.current = newAnalyser;
    return () => {
      console.log("Destroying visualizer");
      newAnalyser.stop();
      newAnalyser.destroy();
    };
  }, [audioRef, visualizerRef]);

  let [currentSong, setCurrentSong] = React.useState("Aktuell keine Musik");
  let [oldSong, setOldSong] = React.useState("Aktuell keine Musik");
  let [currentArtist, setCurrentArtist] = React.useState("");
  let [currentTime, setCurrentTime] = React.useState(0);
  let [length, setLength] = React.useState(0);
  let [isJingle, setIsJingle] = React.useState(false);

  let [nextSong, setNextSong] = React.useState("Nächster Titel");
  let [nextArtist, setNextArtist] = React.useState("");

  let [albumArt, setAlbumArt] = React.useState(null);
  let [oldAlbumArt, setOldAlbumArt] = React.useState(null);

  let oldAlbumArtRef = React.useRef(null);

  React.useEffect(() => {
    async function fetchData() {
      try {
        let response = await fetch(API_URL + "/api/nowplaying");
        let data = await response.json();
        let newCurrentSong = data.currentSong.split(" - ")?.[1] || "";
        setCurrentSong(newCurrentSong);
        setCurrentArtist(data.currentSong.split(" - ")?.[0] || "");
        setCurrentTime(Math.round(data.playbackPosition));
        setLength(Math.round(data.songLength));
        setIsJingle(data.jingle);
        setNextSong(data.nextSong.split(" - ")?.[1] || "");
        setNextArtist(data.nextSong.split(" - ")?.[0] || "");
        if (!newCurrentSong) {
          setCurrentSong("Aktuell keine Musik");
        }
      } catch (e) {
        console.log("Error fetching now playing", e);
        setCurrentSong("Error fetching now playing");
        setCurrentTime(0);
        setLength(0);
      }
    }
    let interval = setInterval(fetchData, 1000);
    fetchData();
    return () => clearInterval(interval);
  }, []);

  let currentSongRef = React.useRef(null);
  let nextSongRef = React.useRef(null);

  const animateNextSongText = React.useCallback(async () => {
    if (currentSongRef.current && nextSongRef.current) {
      document.title = `Als nächstes: ${nextArtist} - ${nextSong} | NootNootFM`;
      currentSongRef.current.style.opacity = 0;
      currentSongRef.current.style.transform = "translateY(-75%)";
      await wait(150);
      nextSongRef.current.style.opacity = 1;
      nextSongRef.current.style.transform = "translateY(-50%)";
      await wait(1000);
      currentSongRef.current.style.transform = "translateY(-25%)";
      await wait(5000);
      nextSongRef.current.style.opacity = 0;
      nextSongRef.current.style.transform = "translateY(-75%)";
      await wait(150);
      currentSongRef.current.style.opacity = 1;
      currentSongRef.current.style.transform = "translateY(-50%)";
      document.title = `${currentSong} - ${currentArtist} | NootNootFM`;
      await wait(500);
      nextSongRef.current.style.transform = "translateY(-25%)";
    }
  }, [currentArtist, currentSong, nextArtist, nextSong]);

  React.useEffect(() => {
    if (currentSong !== oldSong) {
      setOldSong(currentSong);
      resetVote.current();
      setOldAlbumArt(albumArt);
      if (
        isJingle ||
        currentSong === "Error fetching now playing" ||
        currentSong === "Aktuell keine Musik"
      ) {
        setAlbumArt(null);

        // set title author and album for phones
        navigator.mediaSession.playbackState = "playing";
        navigator.mediaSession.metadata = new MediaMetadata({
          title: currentSong,
          artist: currentArtist,
          album: "NootNootFM",
          artwork: [
            {
              src: "https://i.imgur.com/yT0dYZc.png",
              sizes: "512x512",
              type: "image/png",
            },
          ],
        });
      } else {
        getCoverArt(currentSong, currentArtist, (a) => {
          setAlbumArt(a);

          // set title author and album for phones
          navigator.mediaSession.playbackState = "playing";
          navigator.mediaSession.metadata = new MediaMetadata({
            title: currentSong,
            artist: currentArtist,
            album: "NootNootFM",
            artwork: [
              {
                src: a || "https://i.imgur.com/yT0dYZc.png",
                sizes: "512x512",
                type: "image/png",
              },
            ],
          });
        });
      }
      //oldAlbumArtRef.current.style.opacity = 1;
      oldAlbumArtRef.current.animate(
        [
          {
            opacity: 1,
          },
          {
            opacity: 0,
          },
        ],
        {
          duration: 2000,
          easing: "ease-in-out",
        }
      );

      // cache the next song
      getCoverArt(nextSong, nextArtist, (url) => {
        let img = new Image();
        img.src = url;
        console.log("Preloading next song", nextSong, nextArtist);
      });

      setForceVoteOpen(false);
    }
  }, [
    albumArt,
    animateNextSongText,
    currentArtist,
    currentSong,
    isJingle,
    nextArtist,
    nextSong,
    oldSong,
    playPause,
  ]);

  React.useEffect(() => {
    if (!playing) {
      navigator.mediaSession.playbackState = "paused";
    }
  }, [playing]);
  React.useEffect(() => {
    navigator.mediaSession.setActionHandler("play", () => playPause());
    navigator.mediaSession.setActionHandler("pause", () => playPause());
  }, [playPause]);

  React.useEffect(() => {
    if (
      isJingle ||
      currentSong === "Error fetching now playing" ||
      currentSong === "Aktuell keine Musik"
    ) {
      document.title = `NootNootFM`;
      document.querySelector("link[rel='icon']").href =
        "https://i.imgur.com/yT0dYZc.png";
    } else {
      document.title = `${currentArtist} - ${currentSong} | NootNootFM`;
      document.querySelector("link[rel='icon']").href =
        albumArt || "https://i.imgur.com/yT0dYZc.png";
    }
  }, [albumArt, currentSong, currentArtist, isJingle]);

  React.useEffect(() => {
    if (isJingle || !length) return;
    if (Math.floor(currentTime) === Math.floor(length - 40)) {
      animateNextSongText();
    }
  }, [length, currentTime, isJingle, animateNextSongText]);

  const resetVote = React.useRef(() => {});
  const setVoted = React.useRef(() => {});
  const [voted, _setVoted] = React.useState(false);

  let [forceVoteOpen, setForceVoteOpen] = React.useState(false);

  React.useEffect(() => {
    if (isJingle || voted || forceVoteOpen) return;
    let lastTimeAsked = new Date(localStorage.getItem("lastTimeAsked") || 0);
    if (
      currentTime > 50 &&
      currentTime < 80 &&
      Date.now() - lastTimeAsked > 1000 * 60 * 5
    ) {
      if (Math.random() < 0.065) {
        setForceVoteOpen(true);
        localStorage.setItem("lastTimeAsked", Date.now());
      }
    }
  }, [isJingle, forceVoteOpen, currentTime, voted]);

  const mainIconColor = theme.palette.mode === "dark" ? "#fff" : "#000";
  const lightIconColor =
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.4)" : "rgba(0,0,0,0.4)";

  const onVote = async (isPositive) => {
    setForceVoteOpen(false);
    setVoted.current(true);
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          let res = await fetch(API_URL + "/api/vote", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              vote: isPositive ? "up" : "down",
            }),
          });
          if (!res.ok) {
            reject();
          } else {
            resolve();
          }
        } catch (e) {
          reject(e);
        }
      }, "Voted"),
      {
        loading: "Wird gesendet...",
        success: "Erfolgreich abgestimmt!",
        error: "Du kannst nur einmal alle 24 Stunden pro Lied abstimmen.",
      }
    );
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Center>
        <Widget>
          <div
            style={{
              opacity: forceVoteOpen ? 0 : 1,
              pointerEvents: forceVoteOpen ? "none" : "auto",
              transition: "opacity 0.3s ease",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
              <Cover
                style={{
                  backgroundImage: albumArt
                    ? `url(${albumArt})`
                    : "url(https://i.imgur.com/yT0dYZc.png)",
                }}
              />
              <div
                style={{
                  position: "relative",
                  minHeight: 69,
                  flexGrow: 1,
                }}
              >
                <div
                  ref={currentSongRef}
                  style={{
                    position: "absolute",
                    top: "47%",
                    width: "100%",
                    transform: "translateY(-50%)",
                    opacity: 1,
                    transition: "all 0.3s ease",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      fontWeight={500}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      NootNootFM{" "}
                      <span style={{ fontWeight: 200 }}>WebPlayer</span>
                    </Typography>
                    <OverflowScroll>
                      <Typography noWrap>
                        <b>{currentSong}</b>
                      </Typography>
                    </OverflowScroll>
                    <OverflowScroll>
                      <Typography
                        noWrap
                        fontSize={
                          currentArtist.length > 20 ? "0.75rem" : "0.875rem"
                        }
                        letterSpacing={-0.25}
                      >
                        {currentArtist}
                      </Typography>
                    </OverflowScroll>
                  </Box>
                </div>
                <div
                  ref={nextSongRef}
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-25%)",
                    opacity: 0,
                    transition: "all 0.3s ease",
                    width: "100%",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Stack direction="row" spacing={0.5} sx={{ ml: -0.2 }}>
                      <ArrowRightAlt />
                      <Typography variant="subtitle2" fontWeight={600} noWrap>
                        Als nächstes
                      </Typography>
                    </Stack>
                    <OverflowScroll>
                      <Typography noWrap fontSize={"0.95rem"}>
                        <b>{nextSong}</b>
                      </Typography>
                    </OverflowScroll>
                    <OverflowScroll>
                      <Typography
                        noWrap
                        fontSize={
                          nextArtist.length > 20 ? "0.7rem" : "0.825rem"
                        }
                        letterSpacing={-0.25}
                      >
                        {nextArtist}
                      </Typography>
                    </OverflowScroll>
                  </Box>
                </div>
              </div>
            </Box>
            <Slider
              aria-label="time-indicator"
              size="small"
              value={currentTime}
              min={0}
              step={1}
              max={length}
              sx={{
                color:
                  theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
                height: 4,
                "& .MuiSlider-thumb": {
                  width: 0,
                  height: 0,
                  transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                  "&::before": {
                    boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                  },
                },
                "& .MuiSlider-rail": {
                  opacity: 0.28,
                },
                mt: 1,
                pointerEvents: "none",
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: -2,
              }}
            >
              <TinyText>{formatDuration(currentTime)}</TinyText>
              <TinyText>-{formatDuration(length - currentTime)}</TinyText>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: -1,
              }}
            >
              {false && (
                <IconButton aria-label="previous song">
                  <FastRewindRounded
                    fontSize="large"
                    htmlColor={mainIconColor}
                  />
                </IconButton>
              )}
              <IconButton
                aria-label={!playing ? "play" : "pause"}
                onClick={() => playPause()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={"3rem"} />
                ) : !playing ? (
                  <PlayArrowRounded
                    sx={{ fontSize: "3rem" }}
                    htmlColor={mainIconColor}
                  />
                ) : (
                  <PauseRounded
                    sx={{ fontSize: "3rem" }}
                    htmlColor={mainIconColor}
                  />
                )}
              </IconButton>

              {false && (
                <IconButton aria-label="next song">
                  <FastForwardRounded
                    fontSize="large"
                    htmlColor={mainIconColor}
                  />
                </IconButton>
              )}
            </Box>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1, px: 1 }}
              alignItems="center"
            >
              <VolumeSlider volume={volume} setVolume={setVolume} />
              <div style={{ flexGrow: 1 }} />
              <Vote
                reset={resetVote}
                setVoted={setVoted}
                active={!isJingle}
                onVotedChanged={(e) => _setVoted(e)}
              />
            </Stack>
          </div>
          <ForceVoteDialog
            open={forceVoteOpen}
            currentSong={currentArtist + " - " + currentSong}
            onClose={() => setForceVoteOpen(false)}
            onVote={(isPositive) => {
              onVote(isPositive);
            }}
          />
        </Widget>
      </Center>
      <BottomButtonContainer>
        <BottomLink
          href="/dashboard"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: "inherit", textAlign: "center" }}
        >
          Admin Panel
        </BottomLink>
        <BottomButton
          onClick={() => {
            setVisualizerEnabled(!visualizerEnabled);
            localStorage.setItem("visualizerEnabled", !visualizerEnabled);
          }}
        >
          <Typography fontSize="0.75rem">
            Visualizer {visualizerEnabled ? "deaktivieren" : "aktivieren"}
          </Typography>
        </BottomButton>
      </BottomButtonContainer>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "50%",
            bottom: 0,
            left: 0,
            zIndex: 1,
            overflow: "hidden",
            filter: "blur(20px)",
            opacity: visualizerEnabled ? 0.32 : 0,
            visibility: visualizerEnabled ? "visible" : "hidden",
          }}
          ref={visualizerRef}
        />
        <WallPaper
          style={{
            backgroundImage: albumArt
              ? `url(${albumArt})`
              : "url(https://i.imgur.com/yT0dYZc.png)",
            zIndex: -1,
          }}
        />
        <WallPaper
          style={{
            backgroundImage: oldAlbumArt
              ? `url(${oldAlbumArt})`
              : "url(https://i.imgur.com/yT0dYZc.png)",
            zIndex: 0,
            opacity: 0,
          }}
          ref={oldAlbumArtRef}
        />
      </div>
      <Toaster />
    </Box>
  );
}

const ForceVoteButton = styled("button")(({ theme }) => ({
  backgroundColor: "rgba(66, 66, 66, 0.5)",
  color: "#ccc",
  padding: "8px 16px",
  width: "100%",
  borderRadius: 8,
  border: "none",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "1rem",
  fontWeight: 500,
  fontFamily: "Inter",
  gap: 10,
  transition: "0.2s ease",
  "&:hover": {
    backgroundColor: "rgba(66, 66, 66, 0.7)",
  },
}));

const ForceVoteCancelButton = styled("button")(({ theme }) => ({
  position: "absolute",
  top: 16,
  right: 16,
  background: "none",
  color: "#666",
  border: "none",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    color: "#888",
  },
}));

function _ForceVoteDialog({ open, onVote, onClose, currentSong }) {
  // auto close after 10 seconds
  React.useEffect(() => {
    if (open) {
      console.log("Opened force vote dialog");
      let timeout = setTimeout(onClose, 10000);
      return () => clearTimeout(timeout);
    }
  }, [open]);
  return (
    <Box
      style={{
        position: "absolute",
        top: open ? 0 : 20,
        opacity: open ? 1 : 0,
        pointerEvents: open ? "auto" : "none",
        transition: "all 0.3s ease",
        left: 0,
        width: "100%",
        height: "100%",
        padding: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: 16,
      }}
    >
      <Typography variant="subtitle1">Gefällt dir der Song?</Typography>
      <Typography
        variant="subtitle2"
        style={{
          marginTop: "-10px",
          marginBottom: "10px",
          color: "#ffffffaa",
        }}
      >
        {currentSong}
      </Typography>

      <div
        style={{
          display: "flex",
          gap: 16,
          width: "100%",
        }}
      >
        <ForceVoteButton onClick={() => onVote(true)}>
          <ThumbUp />
          Ja
        </ForceVoteButton>
        <ForceVoteButton onClick={() => onVote(false)}>
          <ThumbDown />
          Nein
        </ForceVoteButton>
      </div>
      <ForceVoteCancelButton onClick={onClose}>
        <Close />
      </ForceVoteCancelButton>
    </Box>
  );
}

const ForceVoteDialog = React.memo(_ForceVoteDialog);

function VolumeSlider({ volume, setVolume }) {
  let [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const iconColor =
    theme.palette.mode === "dark" ? "rgb(255,255,255)" : "rgb(0,0,0)";
  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{ mb: 1, px: 1 }}
      alignItems="center"
    >
      {volume < 0.05 ? (
        <IconButton
          size="medium"
          onClick={() => setOpen(!open)}
          sx={{ color: iconColor }}
        >
          <VolumeMuteRounded />
        </IconButton>
      ) : volume < 0.266 ? (
        <IconButton
          size="medium"
          onClick={() => setOpen(!open)}
          sx={{ color: iconColor }}
        >
          <VolumeDownRounded />
        </IconButton>
      ) : (
        <IconButton
          size="medium"
          onClick={() => setOpen(!open)}
          sx={{ color: iconColor }}
        >
          <VolumeUpRounded />
        </IconButton>
      )}
      <Slider
        aria-label="Volume"
        defaultValue={toLinear(volume)}
        onChange={(_, value) => setVolume(toNonLinear(value))}
        min={0}
        max={1}
        step={0.01}
        sx={{
          width: open ? 120 : 0,
          opacity: open ? 1 : 0,
          pointerEvents: open ? "auto" : "none",
          transition: "0.2s ease",
          transitionProperty: "width, opacity",
          color: theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
          "& .MuiSlider-track": {
            border: "none",
          },
          "& .MuiSlider-thumb": {
            width: 12,
            height: 12,
            backgroundColor: "#fff",
            transition: "0.2s ease",
            transitionProperty: "width, height, box-shadow, left",
            "&::before": {
              boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
            },
            "&.Mui-focusVisible, &.Mui-active": {
              boxShadow: "none",
            },
            "&:hover": {
              boxShadow: "none",
              width: 18,
              height: 18,
            },
          },
        }}
      />
    </Stack>
  );
}

function Vote({ reset, active, setVoted: _setVoted, onVotedChanged }) {
  const theme = useTheme();
  const mainIconColor = theme.palette.mode === "dark" ? "#fff" : "#000";

  const [voted, setVoted] = React.useState(false);

  React.useEffect(() => {
    onVotedChanged(voted);
  }, [onVotedChanged, voted]);

  const vote = async (positive = false) => {
    handleClose();
    setVoted(true);
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          let res = await fetch(API_URL + "/api/vote", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              vote: positive ? "up" : "down",
            }),
          });
          if (!res.ok) {
            reject();
          } else {
            resolve();
          }
        } catch (e) {
          reject(e);
        }
      }, "Voted"),
      {
        loading: "Wird gesendet...",
        success: "Erfolgreich abgestimmt!",
        error: "Du kannst nur einmal alle 24 Stunden pro Lied abstimmen.",
      }
    );
  };

  React.useEffect(() => {
    reset.current = () => setVoted(false);
    _setVoted.current = setVoted;
  }, [reset, setVoted, _setVoted]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "vote-popover" : undefined;

  return (
    <>
      {active ? (
        <Tooltip title="Song Bewerten" arrow>
          <IconButton
            size="medium"
            aria-label="vote"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            {voted ? (
              <ThumbsUpDownRounded fontSize="medium" htmlColor="#aaa" />
            ) : (
              <ThumbsUpDownOutlined
                fontSize="medium"
                htmlColor={mainIconColor}
              />
            )}
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Du kannst nur Musik bewerten" arrow>
          <IconButton size="small">
            <StarOutline fontSize="medium" htmlColor="#aaa" />
          </IconButton>
        </Tooltip>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {voted ? (
          <Stack direction="row" sx={{ p: 2, gap: 1 }}>
            <CheckCircleRounded color="success" />
            <Typography>Du hast bereits für dieses Lied abgestimmt.</Typography>
          </Stack>
        ) : (
          <Stack direction="row" sx={{ p: 2, gap: 1 }}>
            <IconButton onClick={() => vote(true)}>
              <ThumbUp />
            </IconButton>
            <IconButton onClick={() => vote(false)}>
              <ThumbDown />
            </IconButton>
          </Stack>
        )}
      </Popover>
    </>
  );
}

async function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function toNonLinear(n) {
  return n * n; // this makes it so that the volume changes are more noticeable at lower volumes
}

function toLinear(n) {
  return Math.sqrt(n);
}
