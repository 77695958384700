import "@fontsource-variable/inter";
import { ThemeProvider, createTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/de";
import React, { createContext } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Secured from "./components/Secured";
import "./index.css";
import DashboardPage from "./pages/dashboard";
import DashboardAutoDJPage from "./pages/dashboard/autoDj";
import DashboardEditSchedulePage from "./pages/dashboard/editSchedule";
import DashboardEditSongPage from "./pages/dashboard/editSong";
import DashboardImportFilePage from "./pages/dashboard/import/importFile";
import DashboardImportPlaylistPage from "./pages/dashboard/import/importPlaylist";
import DashboardImportVideoPage from "./pages/dashboard/import/importVideo";
import DashboardImportsPage from "./pages/dashboard/imports";
import DashboardNewSchedulePage from "./pages/dashboard/newSchedule";
import DashboardSchedulesPage from "./pages/dashboard/schedules";
import DashboardEditImportPage from "./pages/dashboard/songImport";
import DashboardSongsPage from "./pages/dashboard/songs";
import LoginPage from "./pages/login";
import PlayerPage from "./pages/player";
dayjs.locale("de");

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily: "Inter",
  },
});

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/dashboard",
    element: (
      <Secured>
        <DashboardPage />
      </Secured>
    ),
  },
  {
    path: "/dashboard/autodj",
    element: (
      <Secured>
        <DashboardAutoDJPage />
      </Secured>
    ),
  },
  {
    path: "/dashboard/songs",
    element: (
      <Secured>
        <DashboardSongsPage />
      </Secured>
    ),
  },
  {
    path: "/dashboard/songs/:id",
    element: (
      <Secured>
        <DashboardEditSongPage />
      </Secured>
    ),
  },
  {
    path: "/dashboard/schedules",
    element: (
      <Secured>
        <DashboardSchedulesPage />
      </Secured>
    ),
  },
  {
    path: "/dashboard/schedules/new",
    element: (
      <Secured>
        <DashboardNewSchedulePage />
      </Secured>
    ),
  },
  {
    path: "/dashboard/schedules/:id",
    element: (
      <Secured>
        <DashboardEditSchedulePage />
      </Secured>
    ),
  },
  {
    path: "/dashboard/imports",
    element: (
      <Secured>
        <DashboardImportsPage />
      </Secured>
    ),
  },
  {
    path: "/dashboard/imports/:id",
    element: (
      <Secured>
        <DashboardEditImportPage />
      </Secured>
    ),
  },
  {
    path: "/dashboard/import/file",
    element: (
      <Secured>
        <DashboardImportFilePage />
      </Secured>
    ),
  },
  {
    path: "/dashboard/import/playlist",
    element: (
      <Secured>
        <DashboardImportPlaylistPage />
      </Secured>
    ),
  },
  {
    path: "/dashboard/import/video",
    element: (
      <Secured>
        <DashboardImportVideoPage />
      </Secured>
    ),
  },
  {
    path: "/",
    element: <PlayerPage />,
  },
]);

export function navigateTo(path) {
  router.navigate(path);
}

export const API_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:3002"
    : "https://radio.megaluke.de";

createRoot(document.getElementById("root")).render(
  <>
    <PlayerProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <RouterProvider router={router} />
        </ThemeProvider>
      </LocalizationProvider>
    </PlayerProvider>
  </>
);

export const PlayerContext = createContext({
  playing: false,
  playPause: () => {},
  volume: localStorage.getItem("volume") || 0.2,
  setVolume: (v) => {},
  loading: false,
  audioRef: null,
});

let audio = new Audio();
audio.crossOrigin = "anonymous";
audio.volume = 0.2;
audio.onended = () => {
  window.location.reload();
};
audio.onloadedmetadata = () => {
  audio.volume = localStorage.getItem("volume") || 0.2;
};

function PlayerProvider({ children }) {
  let [playing, setPlaying] = React.useState(false);
  let [loading, setLoading] = React.useState(false);
  let [volume, setVolume] = React.useState(
    localStorage.getItem("volume") || 0.2
  );

  React.useEffect(() => {
    if (playing) {
      audio.src = "https://stream.megaluke.de/stream";
      audio.load();
      audio.play();
      setLoading(true);
    } else {
      audio.pause();
    }
    audio.onplaying = () => setLoading(false);
  }, [playing]);

  return (
    <PlayerContext.Provider
      value={{
        playing,
        playPause: () => {
          setPlaying(!playing);
        },
        volume,
        setVolume: (v) => {
          audio.volume = v;
          setVolume(v);
          localStorage.setItem("volume", v);
        },
        loading,
        audioRef: audio,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
}
